import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

export default function NavBar(props)
{
  return(
    <>
    <Navbar className='global-nav' collapseOnSelect expand='md'>
      <Container style={{paddingLeft: '0rem'}} fluid>
        <Navbar.Toggle aria-controls='responsive-navbar-nav'  >
        </Navbar.Toggle>
        <Navbar.Collapse id='responsive-navbar-nav'>
          <Nav>
            <Nav.Item>
              <a 
                className="btn py-2" 
                href="https://www.christieclinic.com/about-us"
                onClick={() => {
                  window.dataLayer.push({
                    'event' : `nav`,
                    'btn' : `About`
                  });
                  return true;
                }}
              >
                <svg
                  style={{display: 'inline-block'}}
                  xmlns="http://www.w3.org/2000/svg"
                  x="0"
                  y="0"
                  enableBackground="new 0 0 22.32 22.32"
                  version="1.1"
                  viewBox="0 0 22.32 22.32"
                  xmlSpace="preserve"
                >
                  <path
                    d="M18.72 4.37c1.62 1.8 2.6 4.18 2.6 6.79 0 5.61-4.55 10.16-10.16 10.16S1 16.77 1 11.16 5.55 1 11.16 1"
                    className="st0"
                  ></path>
                  <path d="M11.16 10.82L11.16 16.17" className="st0"></path>
                  <circle cx="11.15" cy="6.48" r="1.36" className="st1"></circle>
                </svg>
                About Christie Clinic
              </a>
            </Nav.Item>
            <Nav.Item>
              <a 
                className="btn py-2" 
                href="https://www.christieclinic.com/providers/"
                onClick={() => {
                  window.dataLayer.push({
                    'event' : `nav`,
                    'btn' : `Providers`
                  });
                  return true;
                }}
              >
                <i className='ProviderIcon'></i>
                Providers
              </a>
            </Nav.Item>
            <Nav.Item>
              <a 
                className="btn py-2" 
                href="https://www.christieclinic.com/locations/"
                onClick={() => {
                  window.dataLayer.push({
                    'event' : `nav`,
                    'btn' : `Location`
                  });
                  return true;
                }}
              >
                <i className='LocationIcon'></i>
                Locations
              </a>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    {/* <div className="global-nav" >
      <a className="btn" href="https://www.christieclinic.com/about-us">
        <svg
          style={{display: 'inline-block'}}
          xmlns="http://www.w3.org/2000/svg"
          x="0"
          y="0"
          enableBackground="new 0 0 22.32 22.32"
          version="1.1"
          viewBox="0 0 22.32 22.32"
          xmlSpace="preserve"
        >
          <path
            d="M18.72 4.37c1.62 1.8 2.6 4.18 2.6 6.79 0 5.61-4.55 10.16-10.16 10.16S1 16.77 1 11.16 5.55 1 11.16 1"
            className="st0"
          ></path>
          <path d="M11.16 10.82L11.16 16.17" className="st0"></path>
          <circle cx="11.15" cy="6.48" r="1.36" className="st1"></circle>
        </svg>
        About Christie Clinic
      </a>
      <a className="btn" href="https://www.christieclinic.com/providers/">
        <i className='ProviderIcon'></i>
        Providers
      </a>
      <a className="btn" href="https://www.christieclinic.com/locations/">
        <i className='LocationIcon'></i>
        Locations
      </a>
    </div> */}
    </>
  );
}